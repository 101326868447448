<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <ion-item lines="full" mode="md">
                <ion-label position="stacked">{{ nameLabel }}</ion-label>
                <ion-input autocomplete="off" :placeholder="language.name" v-model="image.filename" />
            </ion-item>
            <ion-item lines="none" mode="md">
                <ion-label position="stacked">{{ previewLabel }}</ion-label>
                <ion-icon :src="image.data"></ion-icon>
            </ion-item>
            <button class="custom-button button-outline" @click="deleteImage(image.id)">{{ deleteText }}</button>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">
import {alertController, IonContent, IonIcon, IonInput, IonItem, IonPage} from "@ionic/vue";
import {useRoute} from "vue-router";
import {execute} from "@/mixins/LoadingMixin";
import {DELETE, GET, PUT} from "@/services/HTTP";
import {computed, defineComponent, ref} from "vue";
import FixedFooter from "@/components/FixedFooter.vue";
import {Image} from "@/models/ImageModel";
import {presentNativeAlert} from "@/services/Notify";
import router from "@/router";
import {ImageGroupId} from "@/models/ImageGroupTypes";
import {useStore} from "vuex";

export default defineComponent({
    name: "Image",
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonIcon,
        IonInput,
        FixedFooter
    },
    async ionViewWillEnter() {
        await execute(async () => {
            this.image = await GET('/images/' + this.imageId);
            await this.store.dispatch('app/setPageTitle', this.image.filename.split('.')[0]);
        },  this.isImage ? this.language.errors.loadingImages : this.language.errors.loadingIcons);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const route = useRoute();
        const typeId = computed(() => route.params.imageGroupTypeId as string);
        const isImage = computed(() => typeId.value === ImageGroupId.JourneyImages);
        const imageId = computed(() => route.params.imageId);
        const image = ref({
            id: '',
            filename: '',
            data: ''
        } as Image);
        
        const deleteImage = (id: string) => {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: isImage.value ? language.value.deleteImageQuestion : language.value.deleteIconQuestion,
                message: isImage.value ? language.value.verifyDeleteImageQuestion : language.value.verifyDeleteIconQuestion,
                buttons: [
                    {
                        text: language.value.cancel,
                        role: 'cancel',
                        handler: () => {
                            alertController.dismiss();
                        },
                    },
                    {
                        text: language.value.delete,
                        handler: async () => {
                            await alertController.dismiss();
                            await execute(async () => {
                                await DELETE('/images/' + id);
                                await router.replace({ name: 'images', params: {imageGroupTypeId: typeId.value, imageGroupId: route.params.imageGroupId} });
                            }, isImage.value ? language.value.errors.deletingImage : language.value.errors.deletingIcon);
                        },
                    },
                ]
            });
        }
        
        return {
            imageId,
            isImage,
            footerButtons: computed(() => {
                return ([{
                    class: 'custom-button button-clear',
                    icon: true,
                    iconPos: 'start',
                    handler: () => router.back(),
                    text: language.value.cancel
                },
                {
                    class: 'custom-button button-primary',
                    icon: false,
                    iconPos: '',
                    handler: () => {
                        execute(async () => {
                            await PUT('/images', image.value);
                            await router.replace({ name: 'images', params: {imageGroupTypeId: typeId.value, imageGroupId: route.params.imageGroupId} });
                        }, isImage.value ? language.value.errors.savingImage : language.value.errors.savingIcon);
                    },
                    text: language.value.saveUpdates
                }])
            }),
            image,
            deleteImage,
            store,
            language,
            nameLabel: computed(() => isImage.value ? language.value.imageName : language.value.iconName),
            previewLabel: computed(() => isImage.value ? language.value.imagePreview : language.value.iconPreview),
            deleteText: computed(() => isImage.value ? language.value.deleteImage : language.value.deleteIcon)
        }
    }
});
</script>

<style scoped>
img {
    margin: 0 auto;
    max-width: 250px;
    margin-top: 20px;
}

ion-icon {
    width: 100%;
    font-size: 200px;
    margin-top: 20px;
    color: var(--ion-color-primary);
    cursor: initial;
}
</style>