import { presentNativeAlert } from '@/services/Notify';
import { loadingController } from "@ionic/vue";
import {store} from "@/store/Store";

const language = store.getters['app/language'];

export async function execute(run: () => Promise<any>, defaultMessage = '') {
    const spinner = await loadingController.create({});
    try {
        await spinner.present();
        await run();
    } catch (e) {
        if (e && defaultMessage) {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.error,
                message: defaultMessage,
                buttons: [language.ok.toUpperCase()],
            });
        }
    } finally {
        await spinner.dismiss();
    }
}

export async function executeWithoutSpinner(run: () => Promise<any>, defaultMessage = '') {
    try {
        await run();
    } catch (e) {
        if (e && defaultMessage) {
            presentNativeAlert({
                cssClass: 'custom-alert',
                header: language.error,
                message: defaultMessage,
                buttons: [language.ok.toUpperCase()],
            });
        }
    }
}

export async function saveProgress(run: Function) {
    const loading = await loadingController.create({
        message: language.savingProgress,
    });
    
    try {
        await loading.present();
        await run();
    }
    finally {
        await loading.dismiss();
    }
}