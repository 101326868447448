<template>
    <div class="fixed-footer custom-leftright-padding">
        <button v-for="(button, index) of buttons" :key="index"
            class="custom-button"
            :class="button.class"
            @click="button.handler">
            <DirectionIndicator v-if="button.icon && button.iconPos === 'start'" :is-ltr-forward="false" />
            {{ button.text }}
            <DirectionIndicator v-if="button.icon && button.iconPos === 'end'" :is-ltr-forward="true" />
        </button>
    </div>
</template>

<script lang="ts">

import { FooterButton } from "@/models/FooterButtonsModel";
import {PropType} from 'vue'
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default {
    name: 'FixedFooter',
    props: {
        buttons: { 
            type: Array as PropType<Array<FooterButton>>
        }
    },
    components: {
        DirectionIndicator
    }
}
</script>

<style scoped>
.fixed-footer {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: var(--color-step-1000);
    box-shadow: 0px -1px 10px #00000029;
    gap: 20px;
    justify-content: space-around;
}

.custom-button {
    margin: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
</style>