

import { FooterButton } from "@/models/FooterButtonsModel";
import {PropType} from 'vue'
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default {
    name: 'FixedFooter',
    props: {
        buttons: { 
            type: Array as PropType<Array<FooterButton>>
        }
    },
    components: {
        DirectionIndicator
    }
}
